import React from 'react';
import { getWorkbooksLink } from '@/main/routing.utilities';
import { Navigate, useRouteError } from 'react-router-dom';

// this is a brute-force re-route of every error to the workbooks page. This avoid the need for a wildcard route
// that would get into the way of routes that are not managed by React-Router
export const RouteError: React.FunctionComponent = () => {
  const error = useRouteError();
  console.error(error);

  return <Navigate to={getWorkbooksLink()} />;
};
